import React from "react";
import { Helmet } from "react-helmet";

export default () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta http-equiv="content-encoding" content="gzip; charset=UTF-8" />
    <title>Financial Support System for Youth Sports</title>
    <meta
      property="og:title"
      content="Financial Support System for Youth Sports"
    />
    <meta
      name="twitter:title"
      content="Financial Support System for Youth Sports"
    />

    <link rel="canonical" href="https://www.liftrocket.com" />
    <meta property="og:url" content="https://www.liftrocket.com" />
    <meta name="twitter:url" content="https://www.liftrocket.com" />
    <meta name="facebook-domain-verification" content="k4bb4cyqpxdlm4e88o11uoh6ow0uxq" />

    <meta
      name="description"
      content="We keep kids playing. The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Every dollar in your account earns interest and every dollar you spend earns 2% Scholarship Rewards for your team. Our Lift credit line is the budget-friendly backstop every sports family deserves. Do you run a league, team, sports camp, or training center, or have a kid who plays?"
    />
    <meta
      property="og:description"
      content="We keep kids playing. The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Every dollar in your account earns interest and every dollar you spend earns 2% Scholarship Rewards for your team. Our Lift credit line is the budget-friendly backstop every sports family deserves. Do you run a league, team, sports camp, or training center, or have a kid who plays?"
    />
    <meta
      name="twitter:description"
      content="We keep kids playing. The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Every dollar in your account earns interest and every dollar you spend earns 2% Scholarship Rewards for your team. Our Lift credit line is the budget-friendly backstop every sports family deserves. Do you run a league, team, sports camp, or training center, or have a kid who plays?"
    />
    <meta property="fb:app_id" content="383520185612995" />
    <meta property="og:type" content="article" />

    <link
      rel="icon"
      href="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      property="og:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      name="twitter:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
  </Helmet>
);
